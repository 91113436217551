import React from "react";
import aboutHero from "../../assets/co-founders.jpg";
import Timi from "../../assets/Timi.svg";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import "./about.css";
import { aboutArray } from "./aboutArray";
import { CallToAction, Footer } from "../../components";
import { aboutMindsArray } from "./aboutMindsArray";
import { Link } from "react-router-dom";

function About() {
  return (
    <React.Fragment>
      <div className="about-hero">
        <div className="about-hero-container">
          <div className="about-hero-text">
            <div className="about-hero-main-text">
              Simplifying money management and guiding millennials to financial
              consciousness
            </div>
            <div className="about-hero-subtext">
              At Trakka, we believe there’s a simpler way to plan your finances.
              A more structured and transparent way to track and manage your
              money — with zero difficulties.
              <div className="about-hero-textspace"></div>
              In times where managing money has become a back-breaking task, we
              exist to help people understand how they spend their money and
              know what they spend on all the time.
              <div className="about-hero-textspace"></div>
              Trakka is here to remove guesswork and confusion from your
              financial life. And we’re happy to make money management and
              financial planning easier, more exciting, and a less troubling
              journey for all.
            </div>
            <div className="about-download-icons">
              <div>
                {" "}
                Download now{" "}
                <div>
                  {" "}
                  <FaApple />{" "}
                </div>{" "}
                <div>
                  {" "}
                  <FaGooglePlay />{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="about-hero-image">
            <img
              src="https://ik.imagekit.io/trakka/Compressed_Jpegs/founders-min_MlyGqM3zQ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652610914315"
              alt="aboutHeroImg"
              class="about-hero-img"
              width={471}
            />
          </div>
        </div>
      </div>
      <section className="about-info">
        <h1>Our guiding principles</h1>
        <p>
          We are defined by our resilience and empathy. This shapes our ideals,
          and our drive to give back value to the community, our customers, and
          our partners.
        </p>
        <div className="about-info-container">
          {aboutArray.map(({ title, subText }, index) => {
            return (
              <div key={index} className="about-info-container-inner">
                {/* <div></div> */}
                <h3>{title}</h3>
                <p>{subText}</p>
              </div>
            );
          })}
        </div>
      </section>
      <section className="about-minds">
        <div className="about-minds-container">
          <h1>Minds behind Trakka</h1>
          <p>
            We're a young, close-knit team on an adventure to build something
            enduring, while learning something new, every day.
          </p>
          <div className="about-minds-img-container">
            {aboutMindsArray.map(({ name, role, image }) => {
              return (
                <>
                <div class="image">
                  <img class="image__img" src={image} alt={name} />
                  {/* <div class="image__overlay image__overlay--primary">
                    <div class="image__title">{name}</div>
                    <p class="image__description">{role}</p>
                  </div> */}

                     <div class="image__title">{name}</div>
                  <p class="image__description">{role}</p>
                </div>

             
                  </>
              );
            })}
          </div>
        </div>
      </section>

      <CallToAction />
      <Footer />
    </React.Fragment>
  );
}

export default About;
