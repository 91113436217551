export const testimonialsArray = {
  left: [
    "1525794309852766211",
    "1525761467617878016",
    "1526738678369894405",
    "1524519954661392390",
    "1526798803755474944",
    "1525763049101082624",
    "1528076127742337030",
  ],
  middle: [
    "1526819388384493568",
    "1526455779208876033",
    "1524454800410173441",
    "1524779984530841601",
    "1525107361026801664",
    "1528352222618189824",
    "1530255869732061185",
  ],
  right: [
    "1524166290864279557",
    "1525763049101082624",
    "1524621874046590978",
    "1526839699964411905",
    "1526842445547421697",
    "1530082408397152256",
    "1530071860624384000",
  ],
  mobile: [
    "1525794309852766211",
    "1525761467617878016",
    "1526738678369894405",
    "1524166290864279557",
    "1525763049101082624",
    "1524621874046590978",
    "1526839699964411905",
    "1526819388384493568",
    "1526455779208876033",
    "1524454800410173441",
    "1524779984530841601",
    "1525107361026801664",
    "1528049153456095235",
    "1528352222618189824",
    "1528076127742337030",
    "1530071860624384000",
    "1528305488164700161",
    "1530255869732061185",
    "1530082408397152256",
  ],
};
