import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { CgClose } from 'react-icons/cg';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import menuIcon from '../../assets/menu.svg';

import './navbar.css';

const Navbar = () => {
  const [opened, setOpened] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  const addDownloadsNav = () => {
    if (window.scrollY >= 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  window.addEventListener('scroll', addDownloadsNav);

  return (
    <div className='Navbar'>
      <div className='trakka__navbar'>
        <div className='trakka__navbar-links'>
          <div className='trakka__navbar-links_logo'>
            <NavLink to='/' className='default-logo'>
              <img
                src='https://ik.imagekit.io/trakka/Website_Assets/Trakka-logo_tOyQKCzu7.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611859500'
                alt=''
              />
            </NavLink>
            <NavLink to='/' className='mobile-logo'>
              <img
                src='https://ik.imagekit.io/trakka/Website_Assets/Trakka-logo-white_1x4_h4LiZmBA7.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652886547724'
                alt=''
              />
            </NavLink>
          </div>
        </div>
        <div
          className={
            !scrolled
              ? 'trakka__navbar-sign revslideItem'
              : 'trakka__navbar-sign slideItem'
          }
        >
          <NavLink to='/about' className='about'>
            About
          </NavLink>
          <NavLink to='/help' className='help'>
            Support
          </NavLink>
        </div>

        <div
          className={
            !scrolled
              ? 'download-flex download-icons '
              : 'download-flex download-icons slide'
          }
        >
          <div>
            <a
              target={'_blank'}
              href='https://apps.apple.com/us/app/trakka-budget-expenses/id1612957971'
              rel='noreferrer'
            >
              {' '}
              <FaApple size={30} />{' '}
            </a>{' '}
            <a
              target={'_blank'}
              href='https://play.google.com/store/apps/details?id=com.trakkatechnologies.Trakka'
              rel='noreferrer'
            >
              {' '}
              <FaGooglePlay size={25} />{' '}
            </a>
          </div>
        </div>

        <div className='trakka__navbar-menu'>
          <img src={menuIcon} alt='' onClick={() => setOpened(true)} />

          <div
            className='menu-container'
            style={{
              display: opened ? 'block' : 'none',
            }}
            onClick={() => setOpened(false)}
          >
            <>
              {opened && (
                <div
                  onClick={() => setOpened(false)}
                  style={{
                    // marginTop: 25,
                    width: '90%',
                    overflowY: 'hidden',
                    margin: '25px auto',
                    borderRadius: '10px',
                    // zIndex: 200000,
                    // height: '150vh',
                    backgroundColor: '#121212',
                  }}
                >
                  <div className='ham-container'>
                    <div className='ham-header'>
                      <div className='ham-logo'>
                        <Link to='/' onClick={() => setOpened(false)}>
                          <div className='ham-logo'>
                            <img
                              className='default-logo'
                              src='https://ik.imagekit.io/trakka/Website_Assets/Trakka-logo_tOyQKCzu7.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611859500'
                              alt=''
                            />
                            <img
                              className='mobile-logo'
                              src='https://ik.imagekit.io/trakka/Website_Assets/Trakka-logo-white_1x4_h4LiZmBA7.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652886547724'
                              alt=''
                            />
                          </div>
                        </Link>
                      </div>
                      <div className='close-btn'>
                        <CgClose onClick={() => setOpened(false)} />
                      </div>
                    </div>
                    <div className='ham-links'>
                      <NavLink
                        to='/about'
                        className='ham-about'
                        onClick={() => setOpened(false)}
                      >
                        About
                      </NavLink>
                      <NavLink
                        to='/help'
                        className='ham-help'
                        onClick={() => setOpened(false)}
                      >
                        Support
                      </NavLink>
                      <div className='nav-download'>
                        <div className='nav-download-icons'>
                          <div>
                            <a
                              target={'_blank'}
                              href='https://apps.apple.com/us/app/trakka-budget-expenses/id1612957971'
                              rel='noreferrer'
                            >
                              {' '}
                              <FaApple size={30} />{' '}
                            </a>{' '}
                            <a
                              target={'_blank'}
                              href='https://play.google.com/store/apps/details?id=com.trakkatechnologies.Trakka'
                              rel='noreferrer'
                            >
                              {' '}
                              <FaGooglePlay size={25} />{' '}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
