import React from "react";
import "./testimonials.css";
import { testimonialsArray } from "././testimonialsArray";

// import styled from "styled-components";
// import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
// import "@splidejs/splide/dist/css/splide.min.css";
import { TwitterTweetEmbed } from "react-twitter-embed";
function index() {
  return (
    <div className="Testimonials-outer">
      <div className="Testimonials-header">
        <h3>See what our users are saying</h3>
      </div>
      <div className="Testimonials">
        <div className="overlay-top"></div>
        <div className="overlay-bottom"></div>
        <div className="Testimonials-container">
          <div className="Testimonial-container">
            <div className="TestimonialsLeft">
              {testimonialsArray.left.map((tweetID, index) => {
                return (
                  <div key={index} className="Testimonial-div">
                    <TwitterTweetEmbed
                      tweetId={tweetID}
                      options={{ cards: "hidden" }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="TestimonialsMiddle">
              {testimonialsArray.middle.map((tweetID, index) => {
                return (
                  <div key={index} className="Testimonial-div">
                    <TwitterTweetEmbed
                      tweetId={tweetID}
                      options={{ cards: "hidden" }}
                    />
                  </div>
                );
              })}
            </div>

            <div className="TestimonialsRight">
              {testimonialsArray.right.map((tweetID, index) => {
                return (
                  <div key={index} className="Testimonial-div">
                    <TwitterTweetEmbed
                      tweetId={tweetID}
                      options={{ cards: "hidden", hideThread: "true" }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="TestimonialsMobile">
              {testimonialsArray.mobile.map((tweetID, index) => {
                return (
                  <div key={index} className="Testimonial-div">
                    <TwitterTweetEmbed
                      tweetId={tweetID}
                      options={{ cards: "hidden", hideThread: "true" }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default index;
