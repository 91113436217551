export const faqArray = [
  {
    question: "What is Trakka?",
    answer:
      "Trakka is a personal finance app that helps you solve a problem that plagues virtually everyone - managing your finances. Trakka sets you apart from the crowd by providing you with everything you need to stay on top of your finances by allowing you sync multiple financial accounts to one app, track your expenses and create custom budgets ",
  },
  {
    question: "What makes Trakka different?",
    answer:
      "It’s the simplicity for us. You know your earnings and expenses, and you create custom budgets to live within your means. Also, Trakka helps you categorize your spending. If you spend a thousand naira on food today, Trakka helps you know that you spent a thousand naira on food.",
  },
  {
    question: "Does Trakka have access to my money?",
    answer:
      "No, Trakka does not have access to the money in your synced bank accounts.",
  },
  {
    question: "Do I need debit cards to use Trakka?",
    answer:
      "No, you don’t need your debit card details to sync your bank accounts. Also, Trakka does not use your BVN. All that is needed is your mobile or internet banking login details and you’re good to go.",
  },
  {
    question: "Can I perform transactions with the Trakka app?",
    answer: "As of now, Trakka does not execute bank transactions.",
  },
  {
    question: "How does Trakka track my expenses?",
    answer:
      "Trakka syncs with your multiple financial accounts and helps categorize your transactions across them all. Helping you see your inflow and outflow on different time frames. ",
  },
  {
    question: "Do I need to pay to start using Trakka?",
    answer:
      "Trakka is a freemium app. You don’t need to pay to start using Trakka. And you can sync one of your bank accounts for free. But to be able to sync all your 10 bank accounts … You need to step up a notch! Move from our free tier to a paid subscription plan that costs less than a can of sardines to extend your grip over your finances.",
  },
  {
    question: "How many bank accounts can I sync with Trakka?",
    answer:
      "You can sync as many banks accounts as you want from the numerous banks listed in the app.",
  },
  {
    question: "How does the Trakka budgeting feature work?",
    answer:
      "Trakka helps you create a budget for everything you love to spend on! You can create a budget for food, data, subscription, gifts, and even donations. Set an amount you want to spend on each category. And know when you exceed your budget for that month. Free users can only create monthly budgets. However, users on the paid plan can set custom dates for their budgets. So If you want to create a budget for just 10 days, Trakka’s got you.",
  }
  // {
  //   question:
  //     "Is there a way to know if I stayed within my budget for the month without opening the app?",
  //   answer:
  //     "Yes! Trakka sends all its users a weekly budget summary to show you how you’re doing. ",
  // },
];
