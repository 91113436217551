import React, { useState } from "react";
import "./pricing.css";
import "animate.css/animate.min.css";
import { CgClose } from "react-icons/cg";

function Pricing() {
  const [pricingContent, setPricingContent] = useState("Pricing-Price-content");
  const [position, setPosition] = useState("Pricing-price-why");
  const [price, setprice] = useState("Amount");
  const [reason, setReason] = useState("why-hidden");
  const [pricebox, setPricebox] = useState("Pricing-price");
  const [x, setX] = useState("original-x");

  const changekini = () => {
    setprice(" Amount fade-out");
    setPricingContent(" fade-out");
    setPosition("Pricing-price-why move-up");
    setReason("why-visible");
    setPricebox("Pricing-clickable");
    setX("Pricing-price-x-show");
  };

  const revert = () => {
    setprice(" Amount fade-in");
    setPricingContent("Pricing-Price-content fade-in2");
    setPosition("Pricing-price-why move-down");
    setReason("why-hidden");
    setX("Pricing-price-x");
  };

  return (
    <div className="Pricing" id="pricing">
      <div className="Pricing-container">
        <div className="Pricing-header">
          We’ve made it cheaper than sardines
        </div>
        <div className="Pricing-information">
          <div className="Pricing-price">
            <div className="Pricing-price-header">
              <div className="Pricing-price-header-text">
                <p>Pricing</p>
              </div>
              <div className={x} onClick={revert}>
                <CgClose className="x-size" />
              </div>
            </div>

            <div className={price}>
              ₦500<span className="small">/mo</span>
            </div>
            <div className="why-container">
              <div className={pricingContent}>
                Unlock an untethered experience. Sync multiple bank accounts,
                set custom time periods for your budgets, get automatic account
                refreshes daily and more.
              </div>
              <div className={reason}>
                As much as we wish the key to financial control could be
                provided free of charge. <br />
                <br />
                We want to build a product that stands the test of time, and a
                subscription offering makes it sustainable while providing you
                with a best-in-class product.
                <br />
                <br />
                We love what we do, and we hope you do too #TrakkaCares
              </div>
            </div>

            <div className={position} onClick={changekini}>
              Why isn’t Trakka free?
            </div>
          </div>
          <div className="Pricing-try">
            <p>Try a free account with Trakka</p>
            <div className="Pricing-try-content">
              You don't have to pay right away. Download the Trakka app, sync an
              account and take it for a spin,
            </div>
            <div className="Pricing-display">
              <img
                src={
                  "https://ik.imagekit.io/trakka/Website_Assets/Pricing-iphone_9b5lWE8Sj.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611854787"
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
