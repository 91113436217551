export const aboutArray = [
  {
    title: "Integrity and Openness",
    subText:
      "We build our standards on professional and ethical grounds. We will never compromise the trust our customers and partners have in us. In all our dealings, we will act responsibly and be transparent always.",
  },
  {
    title: "Customer Delight",
    subText:
      "At the core of our existence, we value our customers deeply.  And we will continue to do everything to give them the best experience they can get.",
  },
  {
    title: "Accountability",
    subText:
      "We won't down our words. We have high regard for our employees and our customers, and we will take full responsibility for our words, actions, and inactions.",
  },
  {
    title: "Building for the future",
    subText:
      "We work as a team to build long-lasting and ever-present solutions. We always look beyond now and hope to develop the value that sprouts into the future.",
  },
];
