import React from "react";
import "./ambassadors.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

function index() {
  return (
    <div className="Ambassadors">
      <div className="Ambassadors-container">
        <div className="Ambassadors-text">
          <div className="Ambassadors-main-text">Our Ambassador Programme</div>
          <div className="Ambassadors-subText">
            Trakka is passionate about building a generation of people who have
            achieved financial awareness. It is our goal to help as many people
            as possible to crush their financial goals, starting with financial
            accountability. So to help you get the best out of your money, we’ve
            assembled a team of young & sparky individuals from several campuses
            across the country to bring Trakka to you.
          </div>
        </div>
        <div className="Ambassador-images bigger">
          <AnimationOnScroll
            className="Ambassador-1"
            animateIn="animate__fadeIn"
            animateOnce={true}
          >
            <img
              className="Ambassador-1"
              src="https://ik.imagekit.io/trakka/Compressed_Jpegs/tami-ambassador_OtFx-yeiF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652739339593"
            />
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="Ambassador-2"
            delay="250"
            animateOnce={true}
          >
            <img
              className="Ambassador-2"
              src="https://ik.imagekit.io/trakka/Compressed_Jpegs/josepg-ambassador_Hw4VVY8pT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652739339735"
            />
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="Ambassador-3"
            delay="500"
            animateOnce={true}
          >
            <img
              className="Ambassador-3"
              src="https://ik.imagekit.io/trakka/Compressed_Jpegs/busade-ambassador-cropped_sOZxkw8DC.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652738788992"
            />
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="Ambassador-4"
            delay="750"
            animateOnce={true}
          >
            <img
              className="Ambassador-4"
              src="https://ik.imagekit.io/trakka/Compressed_Jpegs/ambassadors_N0IOLcDGh.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652739799419"
            />
          </AnimationOnScroll>
        </div>
        <div className="Ambassador-images-mobile ">
          <div className="Ambassadors-mobile-flex">
            <div className="left-mobile-images">
              <div className="left-top-images">
                <AnimationOnScroll
                  animateIn="animate__fadeIn"
                  className="Ambassador-1-mobile"
                  animateOnce={true}
                >
                  <img
                    width="100%"
                    height="100%"
                    src="https://ik.imagekit.io/trakka/Compressed_Jpegs/tami-ambassador_OtFx-yeiF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652739339593"
                  />
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="animate__fadeIn"
                  className="Ambassador-2-mobile"
                  delay="250"
                  animateOnce={true}
                >
                  <img
                    width="100%"
                    height="100%"
                    src="https://ik.imagekit.io/trakka/Compressed_Jpegs/josepg-ambassador_Hw4VVY8pT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652739339735"
                  />
                </AnimationOnScroll>
              </div>
              <div className="left-bottom-images">
                <AnimationOnScroll
                  animateIn="animate__fadeIn"
                  className="Ambassador-4-mobile"
                  delay="750"
                  animateOnce={true}
                >
                  <img
                    width="100%"
                    height="100%"
                    src="https://ik.imagekit.io/trakka/Compressed_Jpegs/ambassadors_N0IOLcDGh.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652739799419"
                  />
                </AnimationOnScroll>
              </div>
            </div>
            <div className="right-mobile-images">
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                className="Ambassador-3-mobile"
                delay="500"
                animateOnce={true}
              >
                <img
                  width="100%"
                  height="100%"
                  src="https://ik.imagekit.io/trakka/Compressed_Jpegs/busade-ambassador-cropped_sOZxkw8DC.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652738788992"
                />
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
