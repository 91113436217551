import './App.css';
import React from 'react';
import { Navbar } from './components/index';
import { About, HelpCenter, HomePage, ScrollToTop } from './pages/index';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className='App'>
      <Router>
        <ScrollToTop />
        <Navbar />
        {/* cODER AGBA */}
        <Routes>
          <Route path='/' exact element={<HomePage />}></Route>
          <Route path='/about' exact element={<About />}></Route>
          <Route path='/help' exact element={<HelpCenter />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
