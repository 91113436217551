export const aboutMindsArray = [
  {
    name: 'Kems',
    role: 'Product',
    image:
      'https://ik.imagekit.io/trakka/Compressed_Jpegs/kems__2__2me27-oJD.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1671876324529',
  },
  {
    name: 'Daniel',
    role: 'Growth',
    image:
      'https://ik.imagekit.io/trakka/Compressed_Jpegs/dan?ik-sdk-version=javascript-1.4.3&updatedAt=1652824707178',
  },
  {
    name: 'Boluwatife',
    role: 'Engineering',
    image:
      'https://ik.imagekit.io/trakka/Compressed_Jpegs/boluwatife?ik-sdk-version=javascript-1.4.3&updatedAt=1652824005195',
  },
  {
    name: 'Michael',
    role: 'Engineering',
    image:
      'https://ik.imagekit.io/trakka/Compressed_Jpegs/IMG_splax__2__L-fsRwPbq.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1671876326672',
  },

  {
    name: 'Ayomide',
    role: 'Product',
    image:
      'https://ik.imagekit.io/trakka/Compressed_Jpegs/ayomide?ik-sdk-version=javascript-1.4.3&updatedAt=1652877856116',
  },
  // {
  //   name: "Divine",
  //   role: "Engineering",
  //   image:
  //     "https://ik.imagekit.io/trakka/Compressed_Jpegs/divine?ik-sdk-version=javascript-1.4.3&updatedAt=1652861142490",
  // },

  // {
  //   name: 'Raphael',
  //   role: 'Design',
  //   image:
  //     'https://ik.imagekit.io/trakka/Compressed_Jpegs/ralph?ik-sdk-version=javascript-1.4.3&updatedAt=1652824731726',
  // },
  {
    name: 'Tosin',
    role: 'Engineering',
    image:
      'https://ik.imagekit.io/trakka/Compressed_Jpegs/tosin?ik-sdk-version=javascript-1.4.3&updatedAt=1652877843693',
  },
  {
    name: 'Afolabi',
    role: 'Product',
    image:
      'https://ik.imagekit.io/trakka/Compressed_Jpegs/afo__2__gCXkLJWX_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1671876327176',
  },

  {
    name: 'Rasheed',
    role: 'COO',
    image:
      'https://ik.imagekit.io/trakka/Compressed_Jpegs/rasheed-1?ik-sdk-version=javascript-1.4.3&updatedAt=1652702673533',
  },
  {
    name: 'Timilehin',
    role: 'CEO',
    image:
      'https://ik.imagekit.io/trakka/Website_Assets/Timi_nER3G2kLd.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611858543',
  },
];
