import React from "react";
import "./track.css";
import { useState } from "react";
import "animate.css/animate.min.css";

function Track() {
  const [accounts, setAccounts] = useState("selected");
  const [expenses, setExpenses] = useState("button");
  const [budget, setBudget] = useState("button");

  const [mainText, setMainText] = useState(
    "Sync and monitor all your bank accounts"
  );
  const [subText, setSubText] = useState(
    "Sync multiple bank accounts and leave the rest to Trakka. Automatic tracking and monitoring of your account funds with weekly updates. No thinking, no hassle."
  );
  const [image, setImage] = useState(
    "https://ik.imagekit.io/trakka/Website_Assets/accounts-iphone_WL8wUtqfo.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611839327"
  );

  const setAccountsActive = () => {
    setAccounts("selected");
    setExpenses("button");
    setBudget("button");
    setMainText("Sync and monitor all your bank accounts");
    setSubText(
      "Sync multiple bank accounts and leave the rest to Trakka. Automatic tracking and monitoring of your account funds with weekly updates. No thinking, no hassle."
    );
    setImage(
      "https://ik.imagekit.io/trakka/Website_Assets/accounts-iphone_WL8wUtqfo.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611839327"
    );
  };

  const setExpensesActive = () => {
    setExpenses("selected");
    setAccounts("button");
    setBudget("button");
    setMainText("Track your spending");
    setSubText(
      "Tracking how you spend is one of the most important steps in smart money management. Trakka doesn't just do this for you. It lets you know what exactly you spend your money on. Making every Naira count."
    );
    setImage(
      "https://ik.imagekit.io/trakka/Website_Assets/expenses-iphone_53jytrxkh.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611844621"
    );
  };
  const setBudgetActive = () => {
    setBudget("selected");
    setExpenses("button");
    setAccounts("button");
    setMainText("Create custom-tailored budgets");
    setSubText(
      "You can open a spreadsheet, write down your earnings for the month, and calculate your spending. Meticulously make out the deficits, and start building a foolproof budgeting system. Or, just download Trakka and get all these done for you — with minimal effort."
    );
    setImage(
      "https://ik.imagekit.io/trakka/Website_Assets/budget-iphone_kfkx-eCLy.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611841412"
    );
  };

  return (
    <div className="Track" id="features">
      <div className="Track-container">
        <div className="Track-header">
          Track your spendings and boost your finances
        </div>
        <div className="Track-text"></div>
        <div className="Track-feature-container">
          <div className="Track-tabs">
            <div className="Tabs">
              <div className="Tabs-container">
                <div className="buttons">
                  <div className={accounts} onClick={setAccountsActive}>
                    Accounts
                  </div>
                  <div
                    className={expenses}
                    onClick={setExpensesActive}
                    id="expenses"
                  >
                    Expenses
                  </div>
                  <div className={budget} onClick={setBudgetActive}>
                    Budget
                  </div>
                </div>
                <div className="Tabs-content">
                  <div className="Tabs-main-text">{mainText}</div>
                  <div className="Tabs-subtext">{subText}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="Track-display">
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Track;
