import React from 'react';
import './footer.css';
import logo from '../../assets/Trakka-logo.svg';
import { FaTwitter, FaInstagram } from 'react-icons/fa';

function Footer() {
  var today = new Date();
  return (
    <div className='footer'>
      <div className='footer-container'>
        <div className='contact'>
          <h2>
            {' '}
            <img src={logo} alt='trakka' />{' '}
          </h2>

          <a className='support-mail-text' href='mailto:support@trakka.me'>
            support@trakka.me
          </a>
          <p> ©{today.getFullYear()}. Finstaq Technologies</p>

          <div className='flex'>
            <a
              className='footer-link'
              href='https://twitter.com/trakkahq'
              target='_blank'
              rel='noreferrer'
            >
              <FaTwitter className='footer-icon' size={25} />
            </a>
            <a
              className='footer-link'
              href='https://www.instagram.com/trakkahq/'
              target='_blank'
              rel='noreferrer'
            >
              <FaInstagram className='footer-icon' size={25} />
            </a>
          </div>
        </div>
        <div className='other'>
          <div>
            <h2>Company</h2>

            <ul>
              <li>
                <a className='footer-link' href='/about'>
                  About us
                </a>
              </li>
              <li>
                <a className='footer-link' href='/#features'>
                  Features
                </a>
              </li>
              <li>
                <a className='footer-link' href='/#pricing'>
                  Pricing
                </a>
              </li>
              <li>
                <a
                  className='footer-link'
                  target='_blank'
                  href='https://www.medium.com/trakka'
                  rel='noreferrer'
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2>Legal</h2>
            <ul>
              <li>
                <a
                  className='footer-link'
                  target='_blank'
                  href='https://finstaq.notion.site/trakka-terms-of-use-f946185d9b284e7dafa3dafc27d75c97'
                  rel='noreferrer'
                >
                  Terms of service
                </a>
              </li>
              <li>
                <a
                  className='footer-link'
                  target='_blank'
                  href='https://finstaq.notion.site/Trakka-Privacy-Policy-1727f5a388a04d90819536e4064c4a7d'
                  rel='noreferrer'
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2>Our Apps</h2>
            <ul>
              <li>
                <a
                  className='footer-link'
                  target='_blank'
                  href='https://apps.apple.com/us/app/trakka-budget-expenses/id1612957971'
                  rel='noreferrer'
                >
                  Download for IOS
                </a>
              </li>
              <li>
                <a
                  className='footer-link'
                  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.trakkatechnologies.Trakka'
                  rel='noreferrer'
                >
                  Download for Android
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
