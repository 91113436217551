import React, { useState } from "react";
import "./homePage.css";
import {
  Hero,
  Integrated,
  Track,
  Pricing,
  Ambassadors,
  CallToAction,
  Footer,
  Testimonials,
} from "../../components/index";

function HomePage() {
  return (
    <div className="HomePage">
      <div className="HomePage-container">
        <Hero />
        <Integrated />
        <Track />
        <Pricing />
        <Ambassadors />
        <Testimonials />
        <CallToAction />
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
