import React from 'react';
import './call-to-action.css';
// import phoneScreen from "../../assets/cta-mockup.svg";
import Arrow from '../../assets/Arrow.png';
import { FaApple, FaGooglePlay } from 'react-icons/fa';

function CallToAction() {
  const phoneScreen =
    'https://ik.imagekit.io/trakka/Website_Assets/cta-mockup_qiUUrF7ua.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652619688479';

  return (
    <div className='cta'>
      <div className='cta-container'>
        <img src={Arrow} alt='' className='arrow' />
        <div className='cta-container-text'>
          <h1>Get started today</h1>
          <div className='cta-icons'>
            <p>Download now</p>
            <div>
              <a
                target={'_blank'}
                href='https://apps.apple.com/us/app/trakka-budget-expenses/id1612957971'
                rel='noreferrer'
              >
                {' '}
                <FaApple size={30} />{' '}
              </a>{' '}
              <a
                target={'_blank'}
                href='https://play.google.com/store/apps/details?id=com.trakkatechnologies.Trakka'
                rel='noreferrer'
              >
                {' '}
                <FaGooglePlay size={25} />{' '}
              </a>
            </div>
          </div>
        </div>
        <div className='cta-container-img'>
          <img src={phoneScreen} alt='' className='phone-screen' />
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
