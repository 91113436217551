import React from "react";
import "./hero.css";

import { FaApple, FaGooglePlay } from "react-icons/fa";

import "animate.css/animate.min.css";

function Hero() {
  return (
    <div className="Hero">
      <div className="Hero-container">
        <div className="Hero-text">
          <div
            animateIn="animate__fadeInUp"
            animateOnce={true}
            className="Hero-main-text"
            delay="0"
          >
            Personal finance made simple &{" "}
            <span style={{ color: "var(--secondary-color" }}>easy</span>
          </div>
          <div
            animateIn="animate__fadeInUp"
            delay="250"
            className="Hero-subtext"
            animateOnce={true}
          >
            Trakka is the all-in-one personal finance tool that helps you
            centralize all your bank accounts, track how you spend, and gives
            you the best budgeting system to manage your money.
          </div>
          <div
            className="Hero-download"
            animateIn="animate__fadeInUp"
            animateOnce={true}
            delay="1000"
          >
            <p class="Hero-download-text">Download now</p>
            <div className="Hero-download-icons">
              <div>
                <a
                  target={"_blank"}
                  href="https://apps.apple.com/us/app/trakka-budget-expenses/id1612957971"
                  rel="noreferrer"
                >
                  {" "}
                  <FaApple size={30} />{" "}
                </a>{" "}
                <a
                  target={"_blank"}
                  href="https://play.google.com/store/apps/details?id=com.trakkatechnologies.Trakka"
                  rel="noreferrer"
                >
                  {" "}
                  <FaGooglePlay size={25} />{" "}
                </a>
              </div>

              {/* <div className="Hero-download-flex">
                <AnimationOnScroll
                  animateIn="animate__heartBeat"
                  delay="500"
                  className="Hero-download-apple-logo"
                  initiallyVisible={true}
                >
                  <FaApple />
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="animate__heartBeat"
                  delay="1000"
                  className="Hero-download-google-play-logo"
                  initiallyVisible={true}
                >
                  <FaGooglePlay />
                </AnimationOnScroll>
              </div> */}
            </div>
          </div>
        </div>
        <div className="Hero-image desktop ">
          <img
            src="https://ik.imagekit.io/trakka/Website_Assets/hero-image_QS0VrbgXp.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611847935"
            alt=""
            className="phoneOutline"
          />
        </div>
        <div
          className="Hero-image mobile"
          // animateIn="animate__fadeIn"
          // delay="500"
          // animateOnce={true}
        >
          <img
            src="https://ik.imagekit.io/trakka/Website_Assets/hero-image-mobile_DHef-QuYc.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611848845"
            alt=""
            className="phoneOutline"
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
