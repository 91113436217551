import React, { useState } from 'react';
import './helpCenter.css';
import { FaTwitter, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { CallToAction, Footer } from '../../components';
import { faqArray } from './faqArray';
import arrowDown from '../../assets/arrow-circle-down.svg';

const CustomAccordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className=''>
      <div
        className='accordion-label-container'
        onClick={() => setIsActive(!isActive)}
      >
        <div className='mantine-Accordion-label'>{title}</div>
        <div>
          <img
            style={{
              transform: isActive ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'all 150ms ease',
            }}
            src={arrowDown}
            alt=''
          />
        </div>
      </div>
      <div
        style={{ transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
        className={
          isActive
            ? 'mantine-Accordion-contentInner slide-in-top'
            : 'mantine-Accordion-contentInner'
        }
      >
        {isActive && content}
      </div>
    </div>
  );
};

function HelpCenter() {
  return (
    <React.Fragment>
      <div className='help-hero'>
        <div className='help-hero-container'>
          <div className='help-hero-text'>
            <h1>We’re always ready to help!</h1>
            <p>
              Get in touch! We look forward to hearing from you. Let's get you
              started with the trusted support you need. Contact us or read
              through our FAQs.
            </p>
            <div className='help-hero-icons'>
              <div>
                <a
                  href='https://twitter.com/trakkahq'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaTwitter className='help-hero-icon' />
                </a>
              </div>
              <div>
                <a
                  href='https://www.instagram.com/trakkahq/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaInstagram className='help-hero-icon' />
                </a>
              </div>
              <div>
                <a
                  href='mailto:support@trakka.me'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaEnvelope className='help-hero-icon' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='help-faq'>
        <div className='help-faq-container'>
          <div className='help-faq-container-text'>
            <h2>Frequently Asked Questions</h2>
            <p className='help-faq-container-text-subtitle'>
              Everything you need to know about the product and billing. Can’t
              find an answer? Reach out to our friendly team.
            </p>
          </div>

          {faqArray.map(({ question, answer }, index) => {
            return (
              <CustomAccordion key={index} title={question} content={answer} />
            );
          })}
        </div>
      </div>

      <CallToAction />
      <Footer />
    </React.Fragment>
  );
}

export default HelpCenter;
