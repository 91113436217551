import React from "react";
import "./integrated.css";
import styled from "styled-components";

import cowrywise from "../../assets/cowrywise.svg";

import Marquee from "react-fast-marquee";

function index() {
  return (
    <div className="Integrated">
      <div className="Integrated-container">
        <div className="Integrated-text">
          Integrated with your favourite banks and services
        </div>
        <div className="Integrated-institutions">
          <img
            src={
              "https://ik.imagekit.io/trakka/Website_Assets/zenith-bank_P6miQhlE-.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611862391"
            }
            alt=""
          />
          <img
            src={
              "https://ik.imagekit.io/trakka/Website_Assets/sterling-bank_Im7-cTWFk.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611856550"
            }
            alt=""
          />
          <img
            src={
              "https://ik.imagekit.io/trakka/Website_Assets/standard-chartered_Boq32AWhm.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611855863"
            }
            alt=""
          />
          <img
            src={
              "https://ik.imagekit.io/trakka/Website_Assets/piggyvest_7O2x9htVZ.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611852367"
            }
            alt=""
          />
          <img src={cowrywise} alt="" />
          <p>
            25+<br></br>more
          </p>
        </div>
        <div className="mobile-institutions">
          {/* <Infinite /> */}
          {/* <Wrapper>
            <Splide
              options={{
                perPage: 3,
                arrows: false,
                pagination: false,
                drag: "free",
                gap: "0rem",
                padding: { left: 30, right: 30 },
              }}
            >
              <SplideSlide>
                <Card>
                  <img
                    src={
                      "https://ik.imagekit.io/trakka/Website_Assets/wema-bank_m2_JpzeW4.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611861508"
                    }
                    alt=""
                  />
                </Card>
              </SplideSlide>

              <SplideSlide>
                <Card>
                  <img
                    src={
                      "https://ik.imagekit.io/trakka/Website_Assets/kuda-bank_MgfIBbDg7.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611850075"
                    }
                    alt=""
                  />
                </Card>
              </SplideSlide>
              <SplideSlide>
                <Card>
                  <img
                    src={
                      "https://ik.imagekit.io/trakka/Website_Assets/standard-chartered_Boq32AWhm.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611855863"
                    }
                    alt=""
                  />
                </Card>
              </SplideSlide>

              <SplideSlide>
                <Card>
                  <img
                    src={
                      "https://ik.imagekit.io/trakka/Website_Assets/fidelity-bank-nigeria_woa9GT9jY.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611845006"
                    }
                    alt=""
                  />
                </Card>
              </SplideSlide>

              <SplideSlide>
                <Card>
                  <img
                    src={
                      "https://ik.imagekit.io/trakka/Website_Assets/sterling-bank_Im7-cTWFk.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611856550"
                    }
                    alt=""
                  />
                </Card>
              </SplideSlide>

              <SplideSlide>
                <Card>
                  <p>
                    +25 <br></br>more
                  </p>
                </Card>
              </SplideSlide>
            </Splide>
          </Wrapper> */}
          <Wrapper>
            <Marquee speed={50} gradientWidth={50}>
              <Card>
                <img
                  src={
                    "https://ik.imagekit.io/trakka/Website_Assets/wema-bank_m2_JpzeW4.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611861508"
                  }
                  alt=""
                />
              </Card>
              <Card>
                <img
                  src={
                    "https://ik.imagekit.io/trakka/Website_Assets/kuda-bank_MgfIBbDg7.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611850075"
                  }
                  alt=""
                />
              </Card>
              <Card>
                <img
                  src={
                    "https://ik.imagekit.io/trakka/Website_Assets/standard-chartered_Boq32AWhm.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611855863"
                  }
                  alt=""
                />
              </Card>
              <Card>
                <img
                  src={
                    "https://ik.imagekit.io/trakka/Website_Assets/fidelity-bank-nigeria_woa9GT9jY.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611845006"
                  }
                  alt=""
                />
              </Card>

              <Card>
                <img
                  src={
                    "https://ik.imagekit.io/trakka/Website_Assets/sterling-bank_Im7-cTWFk.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1652611856550"
                  }
                  alt=""
                />
              </Card>
            </Marquee>
          </Wrapper>
        </div>
      </div>
    </div>
  );
}

const Wrapper = styled.div`
  margin: 4rem 0rem;
  width: 100%;
`;
const Card = styled.div`
  height: 102px;
  width: 102px;
  overflow: hidden;
  position: relative;
  margin: 0px 15px;

  @keyframes left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }

  img {
    position: absolute;
    width: 70%;
    height: 70%;
    object-fit: cover;
  }

  p {
    transform: translateY(30%);
  }
`;

export default index;
